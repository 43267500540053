import i18next from "i18next";
import { RawFilterItem } from "./vehicleFilterReferenceDataApi";

const getContractOptionLabelValueMap = (): Map<string, string> => {
  const map = new Map<string, string>();
  map.set("1", "BUY");
  map.set("2", "LEASE");
  return map;
};

export function getContractOptions(): RawFilterItem[] {
  return [
    // todo: add normalized contract options?
    { id: 1, name: i18next.t("CMS_ENUM BUY"), nameNormalized: "" },
    { id: 2, name: i18next.t("CMS_ENUM LEASE"), nameNormalized: "" },
  ];
}

export function resolveContractOptionLabel(label: string): string {
  const map = getContractOptionLabelValueMap();
  return map.get(label) ?? "";
}
