import { WhitelabelConfigType } from "./whitelabelConfigSchema";

export enum MessageTypes {
  INIT = "cm:init",
  READY = "cm:ready",
  HASHCHANGE = "cm:hashchange",
  TITLECHANGE = "cm:titlechange",
}

type InitMessage = {
  type: MessageTypes.INIT;
  payload: WhitelabelConfigType;
};

type ReadyMessage = {
  type: MessageTypes.READY;
};

type HashChangeMessage = {
  type: MessageTypes.HASHCHANGE;
  payload: string;
};

type TitleChangeMessage = {
  type: MessageTypes.TITLECHANGE;
  payload: string;
};

export type Message = InitMessage | ReadyMessage | HashChangeMessage | TitleChangeMessage;

const isCarmarketFrameMessage = (message: Message): message is Message => {
  return message?.type.startsWith("cm:");
};

export const sendMessage = (element: { postMessage: (message: string, origin: string) => void }, message: Message) => {
  element.postMessage(JSON.stringify(message), "*");
};

export const registerMessageListenerOnElement = (
  element: {
    addEventListener: (event: string, listener: (event: MessageEvent) => void) => void;
    removeEventListener: (event: string, listener: (event: MessageEvent) => void) => void;
  },
  listener: (message: Message) => void,
) => {
  const messageListener = (event: MessageEvent) => {
    try {
      const message = JSON.parse(event.data) as Message;
      if (isCarmarketFrameMessage(message)) {
        listener(message);
      }
    } catch (e) {
      // anyone can send messages using postMessage, these might have a different structure and therefore fail
    }
  };
  element.addEventListener("message", messageListener);

  return () => {
    element.removeEventListener("message", messageListener);
  };
};
