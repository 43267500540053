export enum VEHICLE_FACETS {
  VEHICLE_ID = "vehicleId",
  DEALER_ID = "dealerId",
  FIRST_REGISTRATION = "firstRegistration",
  FILTER_FIRST_REGISTRATION = "filterFirstRegistration",
  FUEL_TYPE_GROUP = "fuelTypeGroup",
  FUEL_TYPE = "fuelType",
  BODY_TYPE = "bodyType",
  BODY_TYPE_GROUP = "bodyTypeGroup",
  TRANSMISSION = "transmission",
  TRANSMISSION_GROUP = "transmissionGroup",
  DRIVE_TYPE = "driveType",
  PS = "ps",
  CCM = "ccm",
  CYLINDER_NUM = "cylinderNum",
  BRAND = "brand",
  MODEL = "model",
  BRAND_MODEL = "brandModel",
  OPTIONS = "optionFacets",
  INTERIOR_COLOR = "interiorColor",
  EXTERIOR_COLOR = "exteriorColor",
  UPHOLSTERY = "upholstery",
  MILEAGE = "km",
  PRICE_B2C_GROSS = "priceB2CGross",
  LEASING_RATE_FROM = "leasingRateFrom",
  VEHICLE_CONDITION = "vehicleCondition",
  VEHICLE_CLASS = "vehicleClass",
  VEHICLE_TYPE = "vehicleType",
  DOOR_NUM = "doorNum",
  SEAT_NUM = "seatNum",
  WEIGHT_MAX = "weightMax",
  EMPTY_WEIGHT = "emptyWeight",
  TRAILER_WEIGHT_MAX = "trailerWeightMax",
  BATTERY_CAPACITY = "batteryCapacity",
  BATTERY_RANGE = "batteryRange",
  EMISSION_CLASS = "emissionClass",
  CONTRACT_OPTIONS = "contractOptions",
  OTHER_FILTER_TAGS = "otherFilterTags",
  RESERVED = "reserved",
  QUALITY_CHECKED_NAME = "qualityChecked.name",
}
