import { format, parse } from "date-fns";

export function convertYearToUnixTimestamp(y: number): number {
  return parseInt((new Date(`${y}`).getTime() / 1000).toFixed(0));
}

export function getYearFromDateString(dateString: string): number {
  const yearAsString = dateString.slice(0, 4);
  return parseInt(yearAsString);
}

export function getDateAtMidnight(date: Date): Date {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

function formatMonthAndYear(date: Date) {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}.${year}`;
}

export function convertUnixTimestampToFormattedMonthAndYear(unixTimestamp: number) {
  const date = new Date(unixTimestamp * 1000);
  return formatMonthAndYear(date);
}

export const getMonthAndYearFromDateString = (dateString: string) => {
  const date = getDateFromIsoDateString(dateString);
  return formatMonthAndYear(date);
};

/**
 * helper to get the date in ISO format as yyyy-mm-dd.
 * This is the default format for storing dates in the application.
 */
export function getIsoDate(date: Date): string {
  return format(date, "yyyy-MM-dd");
}

export function getDateFromIsoDateString(dateString: string): Date {
  return parse(dateString, "yyyy-MM-dd", new Date());
}

export function getIsoTime(date: Date): string {
  return format(date, "HH:mm");
}

export function roundToNearestMinuteIncrement(date: Date, minuteIncrement: number): Date {
  const ms = 1000 * 60 * minuteIncrement;
  return new Date(Math.round(date.getTime() / ms) * ms);
}
